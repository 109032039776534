<template>
  <div class="wrapper" id="customer">
    <ctheader
      :titles="pageInfo.titles"
      :back-btn="pageInfo.backBtn"
      :back-path="pageInfo.backPath"
    />
    <div class="content" ref="customer">
      <div class="table-head">
        <div class="left">
          <input
            type="text"
            class="w200 search"
            placeholder="客户名称、负责人"
            @keypress.enter="list()"
            v-model="search.keyword"
          />
          <i class="sdnm-icon sdnm-search" @click="list()"></i>
          <select class="w150" v-model="search.is_official" @change="list()">
            <option value="">全部类型</option>
            <option :value="row.id" v-for="row in official">{{
              row.name
            }}</option>
          </select>
          <div class="flex1"></div>
        </div>
        <div class="right">
          <button
            class="btn primary"
            @click="edit()"
            style="background:#257873;border:1px solid #257873"
          >
            创建客户
          </button>
          <button
            class="btn primary"
            @click="clear()"
            style="background:#257873;border:1px solid #257873"
          >
            清除缓存
          </button>
        </div>
      </div>
      <div class="table-scroll">
        <table class="table" width="100%" :style="{ 'min-width': '800px' }">
          <thead>
            <tr>
              <th class="pw10 ellipsis">客户编号</th>
              <th class="pw20 ellipsis">客户名称</th>
              <th class="pw15 ellipsis">版本名称</th>
              <th class="pw15 ellipsis">负责人</th>
              <th class="pw15 ellipsis">联系电话</th>
              <!-- <th class="pw10">状态</th> -->
              <th class="pw25">操作</th>
            </tr>
          </thead>
          <tbody :style="{ height: protbHeight + 'px' }">
            <tr v-for="row in data">
              <td class="pw10 ellipsis">{{ row.code }}</td>
              <td class="pw20 ellipsis">{{ row.name }}</td>
              <td class="pw15 ellipsis">{{ row.version_name }}</td>
              <td class="pw15 ellipsis">{{ row.contact }}</td>
              <td class="pw15 ellipsis">{{ row.tel }}</td>
              <!-- <td class="pw10">{{row.statusName}}</td> -->
              <td class="pw25 operation">
                <a @click="edit(row)">编辑</a>
                <a @click="detail(row.id, row.code)">查看详情</a>
                <a href="javascript:void(0)" @click="link(row.id)">系统直链</a>
                <a href="javascript:void(0)" @click="hold(row)">同步</a>
              </td>
            </tr>
            <tr v-if="data.length === 0">
              <td class="empty" colspan="9">没有相关记录</td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination
        :start="search.start"
        :total="config.total"
        :page-length="search.length"
        :pageCallback="list"
      ></pagination>
    </div>
    <layers
      :show="isShow"
      :title="layerTitle"
      :width="480"
      :btnLoadDisabled="disabled"
      :saveCallBack="save"
      :closeCallBack="cancel"
    >
      <div class="customer-switch node-switch" v-if="isEdit">
        <div
          class="radio-box"
          @click="changeStatus()"
          style="background:#257873"
        >
          <input type="checkbox" id="status" :checked="status" />
          <label for="status"></label>
          <div class="radio-mask"></div>
          <span class="radio-tip" v-if="status === 0">禁用</span>
          <span class="radio-tip right" v-if="status === 1">启用</span>
        </div>
      </div>
      <div class="form">
        <div class="field">
          <label class="w120 tar">版本名称</label>
          <select class="w300 mgl-8 ver-ali-top" v-model="layer.version_id">
            <option :value="row.id" v-for="row in versionList">{{
              row.name
            }}</option>
          </select>
        </div>
        <div class="field">
          <label class="w120 tar">客户名称</label>
          <input
            type="text"
            class="w300"
            v-model="layer.company_name"
            placeholder=""
            maxlength="20"
          />
        </div>
        <div class="field">
          <label class="w120 tar">负责人</label>
          <input
            type="text"
            class="w300"
            v-model="layer.contact"
            placeholder=""
            maxlength="20"
          />
        </div>
        <div class="field">
          <label class="w120 tar">联系电话</label>
          <input
            type="tel"
            class="w300"
            v-model="layer.tel"
            placeholder=""
            maxlength="11"
            @input="checkInput('tel', layer.tel)"
          />
        </div>
        <div class="field">
          <label class="w120 tar">市场数量上限</label>
          <input
            type="text"
            class="w300"
            v-model="layer.market_num"
            placeholder=""
            maxlength="6"
            @input="checkInput('num', layer.market_num)"
          />
          <span class="unit">家</span>
        </div>
      </div>
    </layers>
    <mimesis
      :show="ensureShow"
      :closeCallBack="ensureCancel"
      :saveCallBack="ensureDone"
      :btnLoadDisabled="endisabled"
      :showIcon="true"
      :isDangerous="true"
      saveBtn="确认"
    >
      <div>确认{{ ensureInfo }}该客户吗？</div>
    </mimesis>
  </div>
</template>
<script>
/**
 * @module app/customer/customer
 * @desc 传蔬农贸版-后台 客户列表
 * @version 0.0.1
 * @author songkexin <songkexin@rongyi.com>
 * @date 2021-06-22
 * @copyright 2021
 */
// import ctheader from '@@/ctheader';
import ctheader from "../../components/ctheader";
import { tableResize, apiPost, isEmpty } from "../../modules/utils";
import { event } from "../../modules/EVENTs";
import pagination from "../../components/pagination";
import mimesis from "../../components/mimesis";
import flatpickr from "../../components/flatpickr";
import radio from "../../components/radio";
import layers from "../../components/layers";
// import pagination from "@@/pagination";
// import mimesis from "@@/mimesis";
// import flatpickr from "@@/flatpickr";
// import radio from "@@/radio";
import APIs from "../../modules/APIs";
import * as URL from "../../modules/URLs";

// import layers from "@@/layers";
import { mapActions } from "vuex";
export default {
  name: "customer",
  components: { ctheader, pagination, layers, flatpickr, radio, mimesis },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.pageInfo.titles = to.meta.title;
      vm.list();
      vm.tResize();
      window.addEventListener("resize", vm.tResize, false);
    });
  },
  created() {
    event.$on("sdnm-radio", this.sdnmRadio);
    this.getList();
  },
  beforeDestroy() {
    event.$off("sdnm-radio", this.sdnmRadio);
  },
  data() {
    return {
      pageInfo: {
        titles: [],
        backBtn: false,
      },
      search: {
        start: 0,
        length: 10,
        keyword: "",
        is_official: "",
      },
      config: {
        total: 0,
        isCreate: true,
      },
      dateConfig: {
        minDate: null,
        maxDate: null,
      }, // 搜索的时间组件的配置
      protbHeight: 0, // 表格高度(px)
      data: [], // 表格数据
      // layer
      isShow: false,
      disabled: false,
      status: 1,
      tempId: "",
      layer: {
        company_name: "",
        contact: "",
        tel: "",
        // is_official: 1,
        // expiration_date: '',
        market_num: "",
        version_id: "",
      },
      layerTitle: "新增客户",
      isEdit: false,
      official: [
        {
          id: 1,
          name: "已购买",
        },
        {
          id: 0,
          name: "试用",
        },
        {
          id: 2,
          name: "测试",
        },
      ], // radio组件数据
      ensureShow: false,
      endisabled: false,
      ensureInfo: "变更状态",
      versionList: [],
    };
  },
  methods: {
    ...mapActions(["setAlert"]),
    /**
     * 系统直链
     * @Author   songkexin     <songkexin@rongyi.com>
     * @DateTime 2021-09-22
     */
    link(id) {
      let _this = this;
      apiPost({
        url: APIs.COMPANY,
        data: {
          action: "get.company.encrypt",
          company_id: id,
        },
        success(res) {
          if (res.code === "0") {
            console.log(
              res.data.url + "/#/superlink?key=" + res.data.supper_encrypt
            );
            window.open(
              res.data.url + "/#/superlink?key=" + res.data.supper_encrypt
            );
          } else {
            _this.setAlert({ msg: res.msg });
          }
        },
        error(res) {},
      });
    },
    //同步
    hold(val) {
      console.log(val);
      let _this = this;
      apiPost({
        url: APIs.COMPANY,
        data: {
          action: "dayu.branch.add",
          hq_code: val.code,
        },
        success(res) {
          if (res.code === "0") {
            _this.setAlert({ msg: res.msg, type: true });
            // this.$message.success(res.msg);
          } else {
            // this.$message.error(res.msg);
            _this.setAlert({ msg: res.msg });
          }
        },
        error(res) {},
      });
    },
    getList() {
      let _this = this;
      apiPost({
        url: APIs.VERSION,
        data: {
          action: "list.version",
          start: 0,
          length: 9999,
          type: 1,
          status: 1,
        },
        success(res) {
          if (res.code === "0") {
            _this.versionList = res.data.filter((el) => el.status === 1);
          }
        },
        error(res) {},
      });
    },
    checkInput(type, val) {
      let regTel = /(?:1[0-9]*)/;
      let regNum = /(?:[1-9][0-9]*)/;
      if (type === "tel") {
        let result = regTel.exec(val);
        this.layer.tel = result !== null ? result[0] : "";
      } else {
        let result = regNum.exec(val);
        this.layer.market_num = result !== null ? result[0] : "";
      }
    },
    detail(id, code) {
      // this.$router.push(`/customer/${id}?code=${code}`);
      console.log(`/customer/${id}?code=${code}`);

      // console.log(URL.CUSTOMERDETAIL + "/" + id + "?code=" + code);
      this.$router.push(URL.CUSTOMERDETAIL + "/" + id + "?code=" + code);
    },
    /**
     * 打开layer
     * @author songkexin <songkexin@rongyi.com>
     * @date 2021-06-22
     */
    edit(row) {
      if (row) {
        this.tempId = row.id;
        this.config.isCreate = false;
        this.layerTitle = "编辑客户";
        this.isEdit = true;
        this.status = row.status;

        this.versionList = this.versionList.filter((el) => el.status !== 0);
        if (
          this.versionList.findIndex((el) => el.id === row.version_id) === -1
        ) {
          this.versionList.push({
            id: row.version_id,
            name: row.version_name,
            status: 0,
          });
        }
        this.layer = {
          company_name: row.name,
          contact: row.contact,
          tel: row.tel,
          is_official: row.is_official,
          expiration_date: row.expiration_date,
          market_num: row.market_num,
          version_id: row.version_id,
        };
      } else {
        this.versionList = this.versionList.filter((el) => el.status !== 0);
        this.config.isCreate = true;
      }
      this.isShow = true;
    },
    //清除缓存
    clear() {
      let _this = this;
      apiPost({
        url: APIs.COMPANY,
        data: {
          action: "cache.delete",
        },
        success(res) {
          if (res.code === "0") {
            _this.setAlert({ msg: res.msg, type: true });
          }
        },
        error(res) {},
      });
    },
    ensureCancel() {
      this.ensureShow = false;
    },
    changeStatus() {
      this.ensureInfo = this.status === 0 ? "启用" : "禁用";
      this.ensureShow = true;
    },
    ensureDone() {
      let data = {
        company_id: this.tempId,
        status: this.status === 0 ? 1 : 0,
      };
      let $this = this;
      apiPost({
        url: APIs.COMPANY,
        data: Object.assign({ action: "company.status.update" }, data),
        success(res) {
          if (res.code === "0") {
            $this.setAlert({ msg: res.msg, type: true });
            $this.list();
            $this.ensureCancel();
            $this.status = data.status;
          } else {
            $this.setAlert({ msg: res.msg });
          }
        },
        error(res) {
          $this.disabled = false;
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    sdnmRadio(index, name) {
      this.layer.is_official = this.official[index].id;
    },
    /**
     * 清空新建输入框消息
     * @author songkexin <songkexin@rongyi.com>
     * @date 2021-06-22
     */
    reset() {
      this.layer = {
        company_name: "",
        contact: "",
        tel: "",
        is_official: 1,
        expiration_date: "",
        market_num: "",
      };
      this.layerTitle = "新增客户";
      this.isEdit = false;
    },
    cancel() {
      this.reset();
      this.isShow = false;
    },
    save() {
      let $this = this;
      let data = this.getData(this.layer);
      if (data === 1) {
        return;
      }
      let action = "company.add";
      if (!this.config.isCreate) {
        data.company_id = this.tempId;
        action = "company.edit";
      }
      $this.disabled = true;
      apiPost({
        url: APIs.COMPANY,
        data: Object.assign({ action: action }, data),
        success(res) {
          $this.disabled = false;
          if (res.code === "0") {
            $this.setAlert({ msg: res.msg, type: true });
            $this.list();
            $this.cancel();
          } else {
            $this.setAlert({ msg: res.msg });
          }
        },
        error(res) {
          $this.disabled = false;
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    getDate(dates, name) {
      this.layer.expiration_date = dates.start;
    },
    getData(d) {
      if (isEmpty(d.company_name)) {
        this.setAlert({ msg: "请输入客户名称" });
        return 1;
      }
      if (isEmpty(d.contact)) {
        this.setAlert({ msg: "请输入负责人" });
        return 1;
      }
      if (isEmpty(d.tel) || d.tel.length !== 11) {
        this.setAlert({ msg: "请输入11位联系电话" });
        return 1;
      }
      // if(isEmpty(d.expiration_date)){
      // 	this.setAlert({ msg: '请选择系统有效期' });
      // 	return 1;
      // }
      if (isEmpty(d.market_num) || d.market_num === 0) {
        this.setAlert({ msg: "请输入市场上限" });
        return 1;
      } else if (d.market_num > 100000) {
        this.setAlert({ msg: "市场上限最大为100000" });
        return 1;
      }
      return d;
    },
    /**
     * 列表获取数据
     * @author songkexin <songkexin@rongyi.com>
     * @date 2021-06-22
     */
    list(index) {
      let $this = this;
      if (index || index === 0) {
        this.search.start = index;
      } else {
        this.search.start = 0;
      }
      apiPost({
        url: APIs.COMPANY,
        data: Object.assign({ action: "company.list" }, $this.search),
        success(res) {
          if (res.code === "0") {
            $this.data = res.data.map((el) => {
              el.statusName =
                el.is_official === 1
                  ? "已购买"
                  : el.is_official === 2
                  ? "测试"
                  : "试用";
              return el;
            });
            $this.config.total = res.total;
          }
          $this.tResize();
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    /**
     * 商品表格的自适应
     * @author wurongjian <wurongjian@rongyi.com>
     * @date   2021-09-29
     */
    tResize() {
      this.protbHeight = tableResize(
        this.$refs.customer.clientHeight,
        250,
        this.data.length
      );
    },
  },
};
</script>
<style lang="less">
@import url("../../less/common.less");
#customer {
  .layer {
    min-width: 480px;
  }
  .form {
    padding-top: 15px;
  }
  .customer-switch {
    float: right;
    margin: 15px;
    margin-right: 35px;
    .radio-box {
      cursor: pointer;
      width: 65px;
      background-color: #253d44;
      > input[type="checkbox"]:checked + label {
        left: 48px;
      }
      > input[type="checkbox"]:checked ~ .radio-mask {
        left: 48px;
        width: 18px;
        background-color: #257873;
      }
      > .radio-mask {
        width: 67px;
        background-color: #aeb5bf;
      }
      .radio-tip {
        font-size: 12px;
        color: #fff;
        position: absolute;
        right: 15px;
        &.right {
          right: 25px;
        }
      }
    }
  }
  em {
    font-style: normal;
  }
  .side-content {
    .fc(12px, #333);
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .tit {
    .fc(20px, #253d44);
  }
  .bl-content {
    .pdt(10px);
    .pdb(10px);
    .lh(30px);
    .brd-b(1px, solid, #eee);
    .txt-l {
      .fc(14px, #666);
    }
  }
  .flex1 {
    flex: 1;
  }
  .label {
    .pd(3px, 5px, 3px, 5px);
    .fc(12px, #fff);
    .bgc(#36a852);
    .brdr(3px);
    .mgl(10px);
  }
  .label.disabled {
    .bgc(#e83f32);
  }
  .label-name {
    color: #a1a1a1;
  }
  .table-head {
    display: flex;
    align-items: center;
    input {
      .mgr(10px);
    }
    input:last-child {
      .mgr(0);
    }
    .sdnm-search {
      // .mgl(10px);
    }
  }
  .form {
    padding-left: 20px;
    label {
      width: 120px;
    }
  }
  .field {
    .tar {
      text-align: right;
    }
    input {
      padding-right: 30px;
    }
    .unit {
      color: #a0b9dc;
      display: inline-block;
      text-align: center;
      cursor: pointer;
      // margin-left: -28px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .m-mimesis {
    z-index: 1002;
  }
}
</style>
